<template>
  <!-- notifications-->
  <section class="notifications">
    <div class="container">
      <div class="notifications-content">
        <div class="notifications-box">
          <div
            class="alert alert-light alert-dismissible fade show"
            role="alert"
          >
            <div class="image">
              <img
                class="img-fluid"
                src="@/assets/images/property/01.jpg"
                alt=""
              />
            </div>
            <div>
              <h4>An example alert with an icon</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dolores alias cum totam, asperiores ipsa amet nostrum quibusdam
                ipsam perferendis quam repellendus unde aspernatur esse,
                mollitia error at optio nobis illo!
              </p>
              <span class="time">10 min ago</span>
            </div>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="notifications-box">
          <div
            class="alert alert-light alert-dismissible fade show"
            role="alert"
          >
            <div class="image">
              <img
                class="img-fluid"
                src="@/assets/images/property/01.jpg"
                alt=""
              />
            </div>
            <div>
              <h4>An example alert with an icon</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dolores alias cum totam, asperiores ipsa amet nostrum quibusdam
                ipsam perferendis quam repellendus unde aspernatur esse,
                mollitia error at optio nobis illo!
              </p>
              <span class="time">10 min ago</span>
            </div>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="notifications-box">
          <div
            class="alert alert-light alert-dismissible fade show"
            role="alert"
          >
            <div class="image">
              <img
                class="img-fluid"
                src="@/assets/images/property/01.jpg"
                alt=""
              />
            </div>
            <div>
              <h4>An example alert with an icon</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dolores alias cum totam, asperiores ipsa amet nostrum quibusdam
                ipsam perferendis quam repellendus unde aspernatur esse,
                mollitia error at optio nobis illo!
              </p>
              <span class="time">10 min ago</span>
            </div>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="notifications-box">
          <div
            class="alert alert-light alert-dismissible fade show"
            role="alert"
          >
            <div class="image">
              <img
                class="img-fluid"
                src="@/assets/images/property/01.jpg"
                alt=""
              />
            </div>
            <div>
              <h4>An example alert with an icon</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dolores alias cum totam, asperiores ipsa amet nostrum quibusdam
                ipsam perferendis quam repellendus unde aspernatur esse,
                mollitia error at optio nobis illo!
              </p>
              <span class="time">10 min ago</span>
            </div>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="notifications-box">
          <div
            class="alert alert-light alert-dismissible fade show"
            role="alert"
          >
            <div class="image">
              <img
                class="img-fluid"
                src="@/assets/images/property/01.jpg"
                alt=""
              />
            </div>
            <div>
              <h4>An example alert with an icon</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dolores alias cum totam, asperiores ipsa amet nostrum quibusdam
                ipsam perferendis quam repellendus unde aspernatur esse,
                mollitia error at optio nobis illo!
              </p>
              <span class="time">10 min ago</span>
            </div>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <h4 class="no-noti">no notifications !</h4>
      </div>
    </div>
  </section>
  <!-- notifications -->
</template>

<script>
export default {
  name: "Notification",
};
</script>

<style>
</style>