<template>

  <!--search area-->
  <section class="search">
    <div class="container">
      <div class="filtration">
        <div class="row">
          <div class="col-sm-9">
            <div class="result">
              <div class="filter">
                <span class="filter-slug"></span>
                <button class="close d-none" type="button">&times;</button>
              </div>
            </div>
          </div>
     
        </div>
      </div>
    </div>
  </section>
  <!--search area-->
</template>

<script>
export default {
     name: "Search",
};
</script>

<style>
</style>