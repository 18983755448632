<template>
  <div
    class="tab-pane fade"
    id="v-pills-3"
    role="tabpanel"
    aria-labelledby="v-pills-3-tab"
  >
    <div class="table-responsive table-area">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>property name</th>
            <th>price</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="shadow-sm">
            <td>
              <a class="property-name" href="single-property.html">
                <div class="image">
                  <img
                    class="img-fluid"
                    src="@/assets/images/backgrounds/hero.jpg"
                    alt=""
                  />
                </div>
                An example alert with an icon</a
              >
            </td>
            <td>50000 EGP</td>
            <td>2021-05-20 15:36:25</td>
            <td>
              <button class="btn remove" type="button">remove</button>
            </td>
          </tr>
          <tr class="shadow-sm">
            <td>
              <a class="property-name" href="single-property.html">
                <div class="image">
                  <img
                    class="img-fluid"
                    src="@/assets/images/backgrounds/hero.jpg"
                    alt=""
                  />
                </div>
                An example alert with an icon</a
              >
            </td>
            <td>50000 EGP</td>
            <td>2021-05-20 15:36:25</td>
            <td>
              <button class="btn remove" type="button">remove</button>
            </td>
          </tr>
          <tr class="shadow-sm">
            <td>
              <a class="property-name" href="single-property.html">
                <div class="image">
                  <img
                    class="img-fluid"
                    src="@/assets/images/backgrounds/hero.jpg"
                    alt=""
                  />
                </div>
                An example alert with an icon</a
              >
            </td>
            <td>50000 EGP</td>
            <td>2021-05-20 15:36:25</td>
            <td>
              <button class="btn remove" type="button">remove</button>
            </td>
          </tr>
          <tr class="shadow-sm">
            <td>
              <a class="property-name" href="single-property.html">
                <div class="image">
                  <img
                    class="img-fluid"
                    src="@/assets/images/backgrounds/hero.jpg"
                    alt=""
                  />
                </div>
                An example alert with an icon</a
              >
            </td>
            <td>50000 EGP</td>
            <td>2021-05-20 15:36:25</td>
            <td>
              <button class="btn remove" type="button">remove</button>
            </td>
          </tr>
          <tr class="shadow-sm">
            <td>
              <a class="property-name" href="single-property.html">
                <div class="image">
                  <img
                    class="img-fluid"
                    src="@/assets/images/backgrounds/hero.jpg"
                    alt=""
                  />
                </div>
                An example alert with an icon</a
              >
            </td>
            <td>50000 EGP</td>
            <td>2021-05-20 15:36:25</td>
            <td>
              <button class="btn remove" type="button">remove</button>
            </td>
          </tr>
          <tr class="shadow-sm">
            <td>
              <a class="property-name" href="single-property.html">
                <div class="image">
                  <img
                    class="img-fluid"
                    src="@/assets/images/backgrounds/hero.jpg"
                    alt=""
                  />
                </div>
                An example alert with an icon</a
              >
            </td>
            <td>50000 EGP</td>
            <td>2021-05-20 15:36:25</td>
            <td>
              <button class="btn remove" type="button">remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <nav class="navigation" aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link arr" href="#!" aria-label="Previous"
              ><span aria-hidden="true">&laquo;</span></a
            >
          </li>
          <li class="page-item active"><a class="page-link" href="#!">1</a></li>
          <li class="page-item"><a class="page-link" href="#!">2</a></li>
          <li class="page-item"><a class="page-link" href="#!">3</a></li>
          <li class="page-item"><a class="page-link" href="#!">4</a></li>
          <li class="page-item"><a class="page-link" href="#!">5</a></li>
          <li class="page-item"><a class="page-link" href="#!">6</a></li>
          <li class="page-item">
            <a class="page-link arr" href="#!" aria-label="Next"
              ><span aria-hidden="true">&raquo;</span></a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
    name:"Favorite"
};
</script>

<style>
</style>